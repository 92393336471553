// Dependencies
import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import * as JsSearch from 'js-search'
// import queryString from "query-string"
import Layout from "../wrappers/Layout"
import { useStoreContext } from "../wrappers/Shop"
import ProductCollection, { SearchResultsCollection } from "../components/ProductCollection"
import { trackSearch } from "../components/useTrackingEvents"
import arrayToObject from "../helpers/arrayToObject"
import useMount from "../hooks/useMount"

function useSearchIndex({
	products, 
	query,
}) {

	
	
	const [data] = useState(products.map(p => {
		const { title, handle, tags } = p
		const { styleid } = arrayToObject(p.metafields, 'key', o => o.value) || {}
		return {
			title,
			handle,
			tags,
			styleid
		}
	}))

	const [searchIndex, setIndex] = useState([])
	const [results, setResults] = useState(null)
	
	useEffect(() => {
		const dataToSearch = new JsSearch.Search('title')
		dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
		dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
		dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex()
		dataToSearch.addIndex('title')
		dataToSearch.addIndex('styleid')
		dataToSearch.addIndex(['tags'])
		dataToSearch.addDocuments(data)
		setIndex(dataToSearch)
	}, [])

	useEffect(() => {
		setResults(null)
		if (query && searchIndex?.search) {
			const findResults = searchIndex.search(query)
			setResults(findResults?.map(r => products.find(p => p.handle === r.handle)))
		}
	}, [query, searchIndex])

	

	

	return [results]
}

const SearchResults = ({ products, location }) => {
	// const query = IS_BROWSER ? queryString.parse(location.search) : location.search
	const searchTerm = decodeURI(location.search?.split('?q=')?.[1] || "")
	const [results] = useSearchIndex({
		products, 
		query: searchTerm,
	})

	return (
		results ? (
			results.length > 0 ? (
				<SearchResultsCollection 
					products={results} 
					location={location} 
					searchTerm={searchTerm} 
					key={`${searchTerm}${results?.length}`} 
				/>
			) : (
				<div className="align-center page-gutters innerx4">
					<h1>{searchTerm ? `No results for "${searchTerm}"` : `No results`}</h1>
				</div>
			)
		) : null
	)
}

const SearchIndex = ({ data, location }) => {

	// Context/helpers
	const { filterProductsByCountry, resolveProductData } = useStoreContext()
	const ready = useMount()
	
	// Merge with live product
	const productsWithPrices = resolveProductData(data.allShopifyProduct.nodes)

	// Filter by country tag flag (us_only, uk_only)
	const productsByCountry = filterProductsByCountry(productsWithPrices)

  return (
    <Layout title={`Search | Harrys London`} willShowSearchBar location={location}>
			{ready && <SearchResults products={productsByCountry} location={location} />}
    </Layout>
  )
}

SearchIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default SearchIndex

export const query = graphql`
  query {
    allShopifyProduct(
      sort: { fields: publishedAt, order: ASC }
    ) {
      nodes {
        ...ProductLink
				descriptionHtml
				tags
      }
    }
  }
`